import { fullLanguageList, SysConfig } from 'resources/sysConfig';

export const defaultConfig: SysConfig = {
    languages: fullLanguageList,
    lng: 'et',
    fallbackLng: 'en',
    tenantRegistration: {
        terms: {
            urlEng: 'https://bidrento.com/{{lang}}/terms-and-conditions/',
            type: 'termsAndConditions',
        },
        extraTerms: null,
    },
    dashboardComponents: {
        DashboardLogo: {
            id: 'DashboardLogo',
            position: 0,
            visible_for: '*',
            except_user_emails: [],
        },
        NotificationsBlock: {
            position: 0,
            visible_for: '*',
            except_user_emails: [],
        },
        UnpaidInvoicesNoticeBlock: {
            position: 0,
            visible_for: '*',
            except_user_emails: [],
        },
        TipsAndTricksPinBlock: {
            position: 0,
            visible_for: '*',
            except_user_emails: [],
        },
        CO2Badge: {
            position: 0,
            visible_for: '*',
            except_user_emails: [],
        },
        InceptionDeviceBlock: {
            position: 0,
            visible_for: '*',
            except_user_emails: [],
        },
        LarvaDevicesWidgetBlock: {
            position: 0,
            visible_for: '*',
            except_user_emails: [],
        },
        SaltoServiceBlock: {
            position: 0,
            visible_for: '*',
            except_user_emails: [],
        },
        ExtraServicesButtonsBlock: {
            position: 0,
            visible_for: '*',
            except_user_emails: ['demo+10@bidrento.com', 'umar.zarip+112233@bidrento.ee'],
        },
        OrderExtraServiceAndMaintenanceButtons: {
            position: 0,
            visible_for: null,
            except_user_emails: ['demo+10@bidrento.com', 'umar.zarip+112233@bidrento.ee'],
        },
        OrderServiceButtonBlock: {
            position: 0,
            visible_for: '*',
            except_user_emails: ['demo+10@bidrento.com', 'umar.zarip+112233@bidrento.ee'],
        },
        CO2OverviewChartBlock: {
            position: 0,
            visible_for: '*',
            except_user_emails: [],
        },
        MeterReadingDashboardChartsBlock: {
            position: 0,
            visible_for: '*',
            except_user_emails: [],
        },
        TipsAndTricksListBlock: {
            position: 0,
            visible_for: '*',
            except_user_emails: [],
        },
    },
    larva: {
        showIfAvailable: true,
        alwaysAvailableForEmails: ['demo+10@bidrento.com', 'demo+99@bidrento.com'],
    },
    meterReading: {
        delete: {
            allowedInHours: 72,
        },
    },
    appType: 'bidrento',
};
